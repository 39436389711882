import { defineStore } from 'pinia'
import axios from "axios"
import { useCartStore } from './cartStore';


// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useMainStore = defineStore('main', {
    state: () => {
        return {
          // all these properties will have their type inferred automatically
          sidebarOpen: false, //Controls opening of sidebar
          user: null,
          token: null,
          access: {},
          orderNo: null,
          location: null,
          locationName: null,
          workingCustCode: null,
          
        }
      },
      //Determines the auth state of a user
      getters: {
        isAuth(state) {
          return !!state.user;
        },
        isAdmin(state) {
          return (state.user?.UserType == 0)
        }
      },
      //Once the token is retrieved we get the users info
      actions: {
        async getUserLogin() {
          const cartStore = useCartStore();
          let t = localStorage.getItem("token")
          if(!this.token && t) {
            this.token = t;
          }
          if(this.token){
             try {
            const response = await axios.post("/SP/Post/v_User_GetFromToken", {
              token: this.token
            });
            this.user = response.data;
            localStorage.setItem('user', JSON.stringify(this.user));

            if(JSON.parse(localStorage.getItem('location'))) {
              this.location = localStorage.getItem('location');
              this.locationName = localStorage.getItem('locationName');
              this.workingCustCode = localStorage.getItem('workingCustCode');
              await this.getOrderNo();
              await cartStore.getCartHeader();
            }
          } catch (e) {
            e;
          }
          }
         
        },
        //Cleans up state and local storage + redirects the user
        signOut() {
          this.user = null;
          this.token = null;
          this.location = null;
          this.locationName = null;
          this.workingCustCode = null;
          localStorage.setItem("token", null);
          localStorage.setItem("user", null);
          localStorage.setItem("location", null);
          localStorage.setItem("workingCustCode", null);
          this.$router.push("/");
        },
        //Get the order number of a logged in user based on their store location
        async getOrderNo() {
          if(this.isAuth) {
            try {
            const response = await axios.post("SP/Post/v_GetOrderNo", {
              LocationCode: this.location,
              CustCode: this.workingCustCode
            });
            
            this.orderNo = response.data.OrderNo ? response.data.OrderNo : null;
  
          } catch(e) {e}
          }
          
  
        }
      },
      
})