<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Disclosure as="nav" class="bg-white shadow" v-slot="{ open }">
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <button
            v-if="mainStore.isAdmin"
            type="button"
            class="px-4 mr-4 md:mx-3 border-r border-gray-200 text-gray-500 hover:bg-slate-100 transition-all focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            @click="mainStore.sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div class="-ml-2 mr-2 flex items-center md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="flex-shrink-0 flex items-center">
            <img
              class="block lg:hidden h-8 w-auto"
              src="@/assets/logos/logo-fab-letters.png"
              alt="FAB"
            />
            <img
              class="hidden lg:block h-8 w-auto"
              src="@/assets/logos/logo-fab.png"
              alt="FAB"
            />
          </div>
          <div class="hidden md:ml-6 md:flex md:space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <router-link
              :to="{ name: 'Home' }"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
              active-class="border-indigo-500"
            >
              Home
            </router-link>
            <router-link
              :to="{ name: 'Digest' }"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
              active-class="border-indigo-500"
            >
              Digest
            </router-link>
            <router-link
              :to="{ name: 'SalesSheet' }"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
              active-class="border-indigo-500"
            >
              Sales Sheet
            </router-link>
            <Menu as="div" class="relative inline-flex text-left">
              <div
                class="inline-flex border-transparent items-center px-1 pt-1 border-b-2 text-sm hover:border-gray-300 hover:text-gray-700"
              >
                <MenuButton class="text-gray-500 inline-flex font-bold">
                  <a>More</a>
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 top-14 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'ShipThisWeek' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >Ship This Week</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'ShipNextWeek' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >Ship Next Week</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'StaffPicks' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >Staff Picks</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        to="sdgsd"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >Tour Dates</router-link
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <button
              @click="$router.push({ name: 'Search' })"
              type="button"
              class="transition-all text-gray-500 hover:text-gray-700 relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-full focus:outline-none hover:ring-gray-300 hover:ring-2 focus:ring-indigo-500"
            >
              <SearchIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              <span>Search</span>
            </button>
          </div>
          <div
            class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center"
            v-if="user == true"
          >
            <button
              :disabled="cartStore.header?.CartCount==0 || cartStore.header == ''"
              @click="$router.push({ name: 'Cart' })"
              type="button"
              class="bg-white relative transition-all p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none hover:ring-gray-300 hover:ring-2 focus:ring-indigo-500"
            >
              <span class="sr-only">Cart</span>
              <ShoppingCartIcon class="h-6 w-6" aria-hidden="true" />
              <span class="absolute bottom-6 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"> {{cartStore.header?.CartCount ? cartStore.header.CartCount : 0}} </span>
            </button>

            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div class="inline-flex items-center px-1 pt-1">
                <MenuButton
                  class="transition-all text-gray-500 border-transparent inline-flex pl-4 pr-1 py-2 font-bold hover:text-gray-700 hover:ring-gray-300 rounded-full text-sm focus:outline-none hover:ring-2 focus:ring-indigo-500"
                >
                  <span>{{ mainStore.user.Email }}</span>
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="{name: 'OrderHistory'}"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      ]"
                      >Order history</router-link
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="{ name: 'Location' }"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      ]"
                    >
                      {{
                        mainStore.locationName
                          ? mainStore.locationName + " - Change Location"
                          : "Select Location"
                      }}</router-link
                    >
                  </MenuItem>
                  <MenuItem as="a" @click="signOut()" v-slot="{ active }">
                    <a
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                      ]"
                      >Sign out</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div class="flex-shrink-0 mx-1" v-if="user == false">
            <button
              @click="$router.push({ name: 'SignIn' })"
              type="button"
              class="transition-colors relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              <span>Sign In</span>
            </button>
          </div>
        </div>
      </div>
      <BreadCrumb class="hidden"></BreadCrumb>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
        <DisclosureButton
          as="a"
          href="#"
          class="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Dashboard</DisclosureButton
        >
        <DisclosureButton
          as="a"
          href="#"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Team</DisclosureButton
        >
        <DisclosureButton
          as="a"
          href="#"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Projects</DisclosureButton
        >
        <DisclosureButton
          as="a"
          href="#"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Calendar</DisclosureButton
        >
      </div>
      <div class="pt-4 pb-3 border-t border-gray-200">
        <div class="flex items-center px-4 sm:px-6">
          <div class="flex-shrink-0">
            <img
              class="h-10 w-10 rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">Tom Cook</div>
            <div class="text-sm font-medium text-gray-500">tom@example.com</div>
          </div>
          <button
            type="button"
            class="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="sr-only">View notifications</span>
          </button>
        </div>
        <div class="mt-3 space-y-1">
          <DisclosureButton
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >Your Profile</DisclosureButton
          >
          <DisclosureButton
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >Settings</DisclosureButton
          >
          <DisclosureButton
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >Sign out</DisclosureButton
          >
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import { useMainStore } from "@/stores/mainStore";
import {useCartStore} from "@/stores/cartStore"
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/vue";
import {
  ShoppingCartIcon,
  MenuIcon,
  XIcon,
  MenuAlt2Icon,
  SearchIcon
} from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ShoppingCartIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
    BreadCrumb,
    MenuAlt2Icon,
    ChevronDownIcon
  },
  setup() {
    const mainStore = useMainStore();
    const cartStore = useCartStore();
    return { mainStore, cartStore };
  },
  computed: {
    user() {
      if (this.mainStore.user) {
        return true;
      }
      return false;
    }
  },

  methods: {
    signOut() {
      this.mainStore.signOut();
    }
  }
};
</script>
