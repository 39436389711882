<template>
  <div class="container mx-auto" v-if="featured">
    <div class="grid grid-cols-2 gap-10 md:gap-20 p-5">
      <div class="col-span-2 bg-slate-50 rounded-sm shadow-md p-5 text-left">
        <p class="text-2xl font-bold">Featured Items</p>
        <div class="p-5 md:grid md:grid-cols-6 gap-10 md:h-96">
          <img class="rounded-sm" :src="item.CInfo_Image" />
          <div class="flex flex-col col-span-5">
            <span class="font-semibold">{{ item.itd_Desc3 }}</span>
            <span class="">{{ item.itd_Desc4 }}</span>
            <span>{{ item.CInfo_Data }}</span>
          </div>
        </div>
        <div>
          <nav
            class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 md:col-span-6"
          >
            <div class="-mt-px w-0 flex-1 flex">
              <button
                @click="prev(), clear()"
                class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
              >
                <ArrowNarrowLeftIcon
                  class="mr-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Previous
              </button>
            </div>
            <div class="hidden lg:-mt-px lg:flex">
              <button
                v-for="(item, index) in featured"
                @click="(itemIndex = index), clear()"
                :key="item"
                :class="
                  itemIndex == index
                    ? 'border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium'
                "
              >
                {{ index + 1 }}
              </button>
              <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
              <button
                @click="next(), clear()"
                class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
              >
                Next
                <ArrowNarrowRightIcon
                  class="ml-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div class="bg-slate-50 rounded-sm shadow-md p-5">
        Latest News RECORD STORE DAY - SATURDAY APRIL 23, 2022 + RSD DROPS DATE
        - SATURDAY JUNE 18, 2022 FOR RSD Approved Indie Record Stores A fresh
        selection of more than 300 special Record Store Day releases, now
        available to order. Quantities are limited; no guarantees on fill. More
        titles will be added as solicitations are received from labels and
        suppliers. To search: Type “2022RSD” in the TITLE field. For April 23
        titles only, type "2022RSD1" in the TITLE field. For June 18 drop titles
        only, type "2022RSD2" in the TITLE field. As always, these special
        editions will be available for a limited time only and in limited
        quantities. Check back regularly and order early for your best chance at
        good fill. Quantities are limited; no guarantees on fill. Please note:
        Due to heavy order volume, it's crucial to send orders as early as
        possible. We will do our best to accommodate requests but some delays
        are to be expected, and with couriers at over capacity, delivery dates
        cannot be guaranteed. We appreciate your patience and understanding in
        these uncertain times. Stay safe and well!
      </div>
      <div class="bg-slate-50 rounded-sm text-left shadow-md p-10">
        <span class="text-2xl font-bold" v-html="aboutTitle"></span>
        <br/>
        <span v-html="aboutContent"></span>
      </div>
    </div>
  </div>
</template>
<script>
import data from "../../public/testItems.json";
import axios from "axios";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon
} from "@heroicons/vue/solid";
export default {
  components: {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon
  },
  data() {
    return {
      testData: data.items,
      itemIndex: 0,
      myInterval: null,
      featured: null,
      aboutContent: null,
      aboutTitle: null,
      newsContent: null,
      newsTitle: null,
    };
  },
  computed: {
    item() {
      return this.featured?.[this.itemIndex % this.featured.length];
    }
  },
  methods: {
    async getContent() {
      await axios
        .post("/SP/Post/qCont_ManageNewsDetail", {
          HeaderID: 3,
          LangCode: 1
        })
        .then((response) => {
          this.aboutContent = response.data.Table[0].CNTD_Story;
          this.aboutTitle = response.data.Table[0].CNTD_Title;
        });
        await axios
        .post("/SP/Post/qCont_ManageNewsDetail", {
          HeaderID: 7,
          LangCode: 1
        })
        .then((response) => {
          this.newsContent = response.data.Table[0].CNTD_Story;
          this.newsTitle = response.data.Table[0].CNTD_Title;
        });
        await axios
        .post("/SP/Post/QComm_GetTopProducts_v2", {
          Flag: 4
        })
        .then((response) => {
          this.featured = response.data.Table;
        });
    },
    prev() {
      if (this.itemIndex == 0) {
        this.itemIndex = this.featured.length - 1;
      } else {
        this.itemIndex--;
      }
    },
    next() {
      this.itemIndex = (this.itemIndex + 1) % this.featured.length;
    },
    auto() {
      this.myInterval = setInterval(() => this.next(), 8000);
    },
    clear() {
      clearInterval(this.myInterval);
    }
  },
  mounted() {
    this.auto();
    this.getContent();
  },
  beforeUnmount() {
    this.clear();
  }
};
</script>
