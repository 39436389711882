<template>
  <div id="nav">
    <SideBar></SideBar>
    <NavBar v-if="!$route.meta.hideNavbar"></NavBar>
  </div>
  <router-view />
</template>
<script>
import NavBar from "./components/NavBar.vue";
import SideBar from "./components/SideBar.vue";
import { useMainStore } from "@/stores/mainStore";
export default {
  components: {
    NavBar,
    SideBar
  },
   setup() {
    const mainStore = useMainStore()
    return {
      mainStore
    }
  },
  data() {
    return {
      loading: false
    }
  },
  created() {
    // if(localStorage.getItem("user")) {
    //   this.mainStore.user = JSON.parse(localStorage.getItem("user"));
    // }
    // await this.mainStore.getUserLogin();
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #f97316;
}
</style>
