import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import "./assets/tailwind.css";
import { createPinia } from 'pinia'
import "./axios";

const pinia = createPinia()

import { markRaw } from 'vue'

pinia.use(({ store }) => {
  store.$router = markRaw(router)
})
createApp(App).use(router).use(pinia).mount("#app");
