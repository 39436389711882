import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeView.vue";
import { useMainStore } from "@/stores/mainStore";
import { useCartStore } from "@/stores/cartStore";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/SignIn",
    name: "SignIn",
    component: () => import("@/views/SignIn.vue"),
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: "/Dashboard",
    name: "DashBoard",
    component: () => import("@/views/DashBoard.vue"),
    meta: {
      hideNavbar: false,
      requiresAdmin: true
     }
  },
  {
    path: "/customermaintenance",
    name: "CustomerMaintenance",
    component: () => import("@/views/CustomerMaintenance.vue"),
    meta: {
      hideNavbar: false,
      requiresAdmin: true
     },
     children: [
      {
        path: "custlist",
        name: "CustList",
        component: () => import("@/components/CustList.vue")
      },
      {
        path: "addcustomer",
        name: "AddCustomer",
        component: () => import("@/components/AddCust.vue")
      },
    ]
  },
  {
    path: "/customer/:code",
    name: "CustomerView",
    component: () => import("@/views/CustView.vue"),
    meta: {
      hideNavbar: false,
      requiresAdmin: true
     },
     children: [
      {
        path: "editcust",
        name: "EditCust",
        props: true,
        component: () => import("@/components/EditCust.vue")
      },
      {
        path: "delivery",
        name: "CustDelivery",
        props: true,
        component: () => import("@/components/CustDelivery.vue")
      },
      {
        path: "delivery/:delcode",
        name: "CustDeliveryEdit",
        props: true,
        component: () => import("@/components/CustDeliveryEdit.vue")
      },
      {
        path: "invoices",
        name: "CustInvoices",
        props: true,
        component: () => import("@/components/InvoiceList.vue")
      },
      {
        path: "orders",
        name: "CustOrders",
        props: true,
        component: () => import("@/components/OrderList.vue")
      },
    ]
  },
  {
    path: "/ordermanagement",
    name: "OrdersManagement",
    component: () => import("@/views/OrderManagement.vue"),
    meta: {
      hideNavbar: false,
      requiresAdmin: true
     },
     children: [
      {
        path: "orders",
        name: "Orders",
        component: () => import("@/components/OrderList.vue")
      },
      {
        path: "invoices/:code?",
        name: "Invoices",
        component: () => import("@/components/InvoiceList.vue")
      },
    ]
  },
  {
    path: "/suppliermanagement",
    name: "SupplierManagement",
    component: () => import("@/views/SupplierManagement.vue"),
    meta: {
      hideNavbar: false,
      requiresAdmin: true
     },
     children: [
      {
        path: "supplierlist",
        name: "SupplierList",
        component: () => import("@/components/SupplierList.vue")
      },
      {
        path: "editsupplier/:code",
        name: "EditSupplier",
        props: true,
        component: () => import("@/components/EditSupplier.vue")
      },
    ]
  },
  {
    path: "/productmanagement",
    name: "ProductManagement",
    component: () => import("@/views/ProductManagement.vue"),
    meta: {
      hideNavbar: false,
      requiresAdmin: true
     },
     children: [
       {
         path: 'productlist',
         name: 'ProductList',
         component: () => import("@/components/ProductList.vue")
       },
       {
        path: 'addproduct',
        name: 'AddProduct',
        component: () => import("@/components/AddProduct.vue")
      },
      {
        path: 'edit/:id',
        name: 'EditProduct',
        component: () => import("@/components/EditProduct.vue")
      }
     ]
    },
    {
      path: "/more",
      name: "MorePage",
      component: () => import("@/views/MorePage.vue"),
      meta: {
        hideNavbar: false,
        requiresAdmin: true
       },
       children: [
        {
          path: 'artist',
          name: 'MoreArtist',
          component: () => import("@/components/ArtistList.vue")
        },
        {
          path: 'genre',
          name: 'MoreGenre',
          component: () => import("@/components/GenreList.vue")
        },
        {
          path: 'format',
          name: 'MoreFormat',
          component: () => import("@/components/FormatList.vue")
        },
        {
          path: 'Label',
          name: 'MoreLabel',
          component: () => import("@/components/LabelList.vue")
        }
       ]
      },
      {
        path: "/sales",
        name: "MoreSales",
        component: () => import("@/views/MoreSalesPage.vue"),
        meta: {
          hideNavbar: false,
          requiresAdmin: true
         },
         children: [
          {
            path: 'courrier',
            name: 'Courrier',
            component: () => import("@/components/CourrierList.vue")
          },
          {
            path: 'currency',
            name: 'Currency',
            component: () => import("@/components/CurrencyList.vue")
          },
          {
            path: 'terms',
            name: 'Terms',
            component: () => import("@/components/TermsList.vue")
          },
          {
            path: 'ReturnReaons',
            name: 'ReturnReasons',
            component: () => import("@/components/ReturnReasonsList.vue")
          }
         ]
        },
  {
    path: "/usermaintenance",
    name: "UserMaintenance",
    component: () => import("@/views/UserMaintenance.vue"),
    meta: {
      hideNavbar: false,
      requiresAdmin: true
     },
     children: [
      {
        path: "userlist",
        name: "UserList",
        component: () => import("@/components/UserList.vue")
      },
      {
        path: "newusers",
        name: "NewUsers",
        component: () => import("@/components/NewUsers.vue")
      },
      {
        path: "managepickers",
        name: "ManagePickers",
        component: () => import("@/components/ManagePickers.vue")
      },
      {
        path: "managesalesreps",
        name: "ManageSalesReps",
        component: () => import("@/components/ManageSalesReps.vue")
      },
      {
        path: "adduser",
        name: "AddUser",
        component: () => import("@/components/AddUser.vue")
      },
      {
        path: "edituser/:user",
        name: "useredit",
        props: true,
        component: () => import("@/components/EditUser.vue")
      },
     ]
  },
  {
    path: "/Digest",
    name: "Digest",
    component: () => import("@/views/DigestPage.vue"),
    meta: {
      hideNavbar: false,
     }
  },
  {
    path: "/SalesSheet",
    name: "SalesSheet",
    component: () => import("@/views/SalesSheet.vue"),
    meta: {
      hideNavbar: false,
     }
  },
  {
    path: "/ship/thisweek",
    name: "ShipThisWeek",
    component: () => import("@/views/ShipThisWeek.vue"),
    meta: {
      hideNavbar: false,
     }
  },
  {
    path: "/ship/nextsweek",
    name: "ShipNextWeek",
    component: () => import("@/views/ShipNextWeek.vue"),
    meta: {
      hideNavbar: false,
     }
  },
  {
    path: "/staffpicks",
    name: "StaffPicks",
    component: () => import("@/views/StaffPicks.vue"),
    meta: {
      hideNavbar: false,
     }
  },
  {
    path: "/Search",
    name: "Search",
    component: () => import("@/views/SearchPage.vue"),
    meta: {
      hideNavbar: false,
     }
  },
  {
    path: "/Cart",
    name: "Cart",
    component: () => import("@/views/CartPage.vue"),
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      requiresCart: true
     }
  },
  {
    path: "/Checkout",
    name: "Checkout",
    component: () => import("@/views/CheckoutPage.vue"),
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      requiresCart: true
     }
  },
  {
    path: "/location",
    name: "Location",
    component: () => import("@/views/CustLocation.vue"),
    meta: {
      hideNavbar: true,
      requiresAuth: true
     }
  },
  {
    path: "/history",
    name: "OrderHistory",
    component: () => import("@/views/OrderHistory.vue"),
    meta: {
      hideNavbar: false,
      requiresAuth: true
     },
     children: [
      {
        path: "OnOrder",
        name: "ItemsOnOrder",
        component: () => import("@/components/ItemsOnOrderList.vue")
      },
      {
        path: "Invoices",
        name: "HistoryInvoices",
        component: () => import("@/components/HistoryInvoices.vue")
      },
      {
        path: "Orders",
        name: "HistoryOrders",
        component: () => import("@/components/HistoryOrders.vue")
      }
     ]
  },
  {
    path: "/contentmanager",
    name: "ContentManager",
    component: () => import("@/views/ContentManager.vue"),
    meta: {
      requiresAuth: true
     },
     children: [
      {
        path: "edit",
        name: "ContentEditor",
        component: () => import("@/components/ContentEditor.vue")
      },
      {
        path: "news",
        name: "ContentNews",
        component: () => import("@/components/ContentNews.vue")
      },
      {
        path: "newsedit/:id",
        name: "ContentNewsEdit",
        component: () => import("@/components/ContentNewsEdit.vue")
      }
     ]
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("@/views/TestPage.vue"),
    meta: {
      hideNavbar: true,
      requiresAuth: true
     }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to) => {
  const mainStore = useMainStore()
  if(!mainStore.isAuth) {
    if(localStorage.getItem("user")) {
      mainStore.user = JSON.parse(localStorage.getItem("user"));
    }
    await mainStore.getUserLogin();
  }
  if(to.name != 'Location' && !mainStore.location && mainStore.isAuth) {
    return {name: 'Location'}
  }
  
})
router.beforeEach((to) => {
  const mainStore = useMainStore()
  if(to.meta.requiresAdmin == true && !mainStore.isAdmin) {
    router.push("/");
  }
  
})

router.beforeEach((to) => {
  const mainStore = useMainStore()
  if(to.meta.requiresAuth == true && !mainStore.isAuth) {
    router.push("/");
  }
  
})

router.beforeEach((to) => {
  const cartStore = useCartStore()
  if(to.meta.requiresCart == true && cartStore.header?.CartCount == 0) {
    router.push("/");
  }
  
})
router.beforeEach(() => {
  const cartStore = useCartStore()
  const mainStore = useMainStore()
  if(mainStore.orderNo) {
    cartStore.getCartHeader()
  }
  
})


export default router;
